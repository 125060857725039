import React from "react";
import PropTypes from "prop-types";

import "./global.scss";
import styles from "./layout.module.scss";

const Layout = ({ children }) => {
  return (
    <>
      <div className={styles.container}>
        <main>{children}</main>
        <footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
