import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import me from "../images/me.jpg";

import styles from "./index.module.scss";

const skills = [
  "CSS",
  "HTML",
  "JavaScript",
  "Node.js",
  "git",
  "GNU/Linux",
  "Ubuntu",
  "Python",
  "Django",
  "Express",
  "MongoDB",
  "React Native",
  "React",
];

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section>
      <h1 className={styles.title}>Nikolai Røed Kristiansen</h1>
      <p>Web Developer from Oslo, Norway</p>
      <h2>About</h2>
      <p className={styles.about}>
        <img src={me} alt="Me" width={150} height={150} className={styles.me} />
        Hi, I'm Nikolai and I work with newer web technology to{" "}
        <em>build quality websites and web applications</em>. I am comfortable
        working with the full web stack, from webserver configuration and back
        end services to front end JavaScript. I cooperate closely with web
        designers to create good user experiences on both desktop and mobile
        devices.
      </p>
    </section>
    <section>
      <h2>Skills</h2>
      <p>
        {`${skills.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        }).join(', ')} and more.`}
      </p>
      <h2>Profiles</h2>
      <p>
        <ul>
          <li>
            <a href="https://github.com/nikolaik">GitHub</a>
          </li>
          <li>
            <a href="http://no.linkedin.com/in/nikolaik">LinkedIn</a>
          </li>
          <li>
            <a href="https://twitter.com/nikolaik">Twitter</a>
          </li>
          <li>
            <a href="https://facebook.com/nikolai.kristiansen">Facebook</a>
          </li>
        </ul>
      </p>
      <h2>Contact</h2>
      <p>
        <ul>
          <li>
            Telephone: <a href="tel:+4748105885">+47 481 058 85</a>
          </li>
          <li>
            Email: <a href="mailto:nikolai@nkweb.no">nikolai@nkweb.no</a>
          </li>
        </ul>
      </p>
      <p>
        Nikolai Kristiansen Web
        <br />
        <a href="https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=895715832">
          895 715 832 MVA
        </a>
        <br />
        Oslo, Norway
        <br />
      </p>
    </section>
  </Layout>
);

export default IndexPage;
